.download-brochure-form {
  position: absolute;
  top: 81%; 
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 12px 10px;  
  border-radius: 8px;  
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);  
  display: flex;
  align-items: center;
  gap: 16px;  
  z-index: 50;
  max-width: 70%;  
  width: 80%; 
  flex-wrap: wrap;
}


.form-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap; 
}

.form-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
  min-width: 250px;
  width: 100%; 
  box-sizing: border-box; 
}

.download-button {
  background-color: #dca52e;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.download-button:hover {
  background-color: #bf9426;
}

.input-error {
  border: 2px solid #d9534f; 
  background-color: #fff; 
  color: #333; 
}

.error-text {
  display: none; 
}

@media (max-width: 1024px) {
  .download-brochure-form {
    position: static;
    transform: none; 
    margin: 20px auto; 
    max-width: 90%;
  }

  .form-container {
    flex-direction: column;
    gap: 15px;
  }

  .form-input,
  .download-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .download-brochure-form {
    padding: 15px 20px;
  }

  .form-container {
    gap: 10px;
  }

  .form-input,
  .download-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .download-brochure-form {
    padding: 10px 15px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .form-input {
    font-size: 14px;
    padding: 8px;
  }

  .download-button {
    font-size: 14px;
    padding: 10px;
  }
}
