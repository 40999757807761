.desktop-masterpiece-section {
  position: relative; 
  text-align: center;
  padding: 0 80px;  
  /* background-color: #D9D9D9;  */

}

.desktop-masterpiece-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 25%;
  background-color: #D9D9D9;
  z-index: -1; 
}

.desktop-masterpiece-section-title {
  margin-bottom: 10px;
  font-family: "Montserrat";
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 100px auto 60px auto;
  /* margin-top: 65px ;
  margin-bottom: 100px; */
}

.desktop-masterpiece-section-subtitle {
  color: #414042;
  font-family: "Montserrat";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin-bottom: 44px;
}

.desktop-masterpiece-image-grid {
  margin: 0 -60px;
}

.desktop-masterpiece-image-container {
  position: relative;
  padding: 30px 0;
}

.desktop-masterpiece-image-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  /* height: 476px;  */
}

.desktop-masterpiece-project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktop-masterpiece-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 50%
  );
  color: white;
  text-align: center;
  padding: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* height: 100%;  */
}

.desktop-masterpiece-image-wrapper:hover .desktop-masterpiece-image-overlay {
  opacity: 1;
  transform: scale(1.05);
}

.desktop-masterpiece-image-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: auto;
   /* Align title to the bottom */
}

.desktop-masterpiece-image-subtitle {
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 20px;
}

.desktop-masterpiece-read-more-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: -30px;
}

.desktop-masterpiece-read-more-icon {
  width: 70px !important;
  height: 70px !important;
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
}

.desktop-masterpiece-read-more-icon.rotated {
  transform: translateX(-50%) rotate(180deg);
  top: -50px;
}

.desktop-masterpiece-additional-content {
  margin-top: 20px;
}
